import { Box } from '@material-ui/core';
import { set } from 'lodash';
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';

import { useOffers } from '@/hooks/useOffers';
import { IOfferIds } from '@/models/ILandingPage.interface';
import { IOfferItem } from '@/models/IOffer.interface';
import { OfferType } from '@/models/OfferTypeName.enum';
import { sortByRecurringPeriod } from '@/utils/offers';

import { Offer } from './Offer';

interface SelectOfferProps {
  selectedOffer: IOfferItem | null;
  includedIds: IOfferIds[] | undefined;
  initialOffer?: IOfferItem | null;
  onSelectedChange: (id: IOfferItem) => void;
}

export const SelectOffer: FC<SelectOfferProps> = ({ initialOffer, includedIds, selectedOffer, onSelectedChange }) => {
  const { offers, offersStylesConfig } = useOffers();

  const mappedOffers: IOfferItem[] = useMemo(() => (offers ? offers.map((offer) => offer.node) : []), [offers]);

  const filteredOffers = useMemo<IOfferItem[]>(() => {
    if (!mappedOffers || !includedIds) {
      return [];
    }

    if (includedIds.length === 0) {
      return mappedOffers;
    }

    const _offerIds = includedIds.map((o) => o.offerId);
    const filteredOffers = mappedOffers.filter((offer) => _offerIds.includes(offer.id));

    const subscribeTypeOffers = filteredOffers
      ?.filter((offer) => offer.__typename === OfferType.SubscribeType)
      .sort(sortByRecurringPeriod);

    const passTypeOffers = filteredOffers?.filter((offer) => offer.__typename === OfferType.PassType);

    return [...subscribeTypeOffers, ...passTypeOffers];
  }, [mappedOffers, includedIds]);

  useEffect(() => {
    if (!selectedOffer?.id) {
      onSelectedChange(initialOffer ?? filteredOffers[0]);
    }
  }, [selectedOffer, filteredOffers]);

  return (
    <>
      {filteredOffers.map((offer) => (
        <Box mb={4} key={offer.id}>
          <Offer
            data={offer}
            checked={selectedOffer?.id === offer.id}
            offersStylesConfig={offersStylesConfig?.find((config) => config.magineOfferId === offer.id)}
            handleChange={() => onSelectedChange(offer)}
          />
        </Box>
      ))}
    </>
  );
};
