import { SerializedStyles, css } from '@emotion/react';
import styled from '@emotion/styled';
import NextLink from 'next/link';
import { CSSProperties, FC, ReactNode } from 'react';

import Arrow from '@/assets/icons/arrows/DF-arrow-text_16px.svg';

import { Typography } from '../Typography';
import DFArrow from '../icons/DFArrow';

interface LinkWrapperProps {
  align?: 'center' | 'left';
}

const LinkWrapper = styled.div<LinkWrapperProps>`
  padding: 6px 8px;
  display: flex;

  justify-content: ${({ align }) => (align === 'left' ? 'flex-start' : 'center')};
  text-align: ${({ align }) => (align === 'left' ? 'left' : 'center')};
  cursor: pointer;
  text-underline-offset: 2px;
  &:hover {
    text-decoration: underline;
    text-decoration-color: ${({ color }) => color ?? '#FFFFFF'};
  }
`;

interface CollectionLinkProps {
  customColor?: string;
  align?: string;
  styles?: SerializedStyles;
}

interface StyledImgProps {
  color?: string;
  hoverColor?: string;
  size?: number;
  arrowStyles?: SerializedStyles;
  placement?: 'left' | 'right';
}

const StyledNextLink = styled(NextLink)<CollectionLinkProps>`
  font-size: 18px;
  display: inline-block;
  font-family: 'SunsetGothicPro';
  color: ${(props) => props.color ?? '#FFFFFF'};
  ${(props) => props.styles ?? ''};
`;

const StyledButton = styled.div<CollectionLinkProps>`
  font-size: 18px;
  display: inline-block;
  font-family: 'SunsetGothicPro';
  cursor: pointer;
  ${(props) => props.styles ?? ''};
`;

const ArrowWrapper = styled.div<StyledImgProps>`
  margin-right: ${(props) => (props.placement === 'left' ? '1rem' : '0')};
  margin-left: ${(props) => (props.placement === 'right' ? '1rem' : '0')};
  rotate: ${(props) => (props.placement === 'left' ? '180deg' : '0deg')};
  height: 12px;

  svg path {
    stroke: ${(props) => props.color ?? 'inherit'};
    &:hover {
      stroke: ${(props) => props.hoverColor ?? 'inherit'};
    }
  }

  ${(props) => props.arrowStyles ?? ''};
`;

interface LinkButtonProps {
  align?: 'center' | 'left';
  color?: string;
  arrowColor?: string;
  path?: string;
  fontStyle?: 'italic' | 'normal';
  onClick?: () => void;
  styles?: SerializedStyles;
  style?: CSSProperties;
  className?: string;
  arrowPlacement?: 'right' | 'left';
  arrowStyles?: SerializedStyles;
  fontStyles?: SerializedStyles;
  children?: ReactNode;
  fontWeight?: string;
  target?: string;
}

const LinkButton: FC<LinkButtonProps> = ({
  align = 'left',
  color = '#FFFFFF',
  arrowColor,
  path = '',
  fontStyle,
  onClick,
  styles,
  style,
  className,
  arrowPlacement,
  arrowStyles,
  fontStyles,
  children,
  fontWeight = '500',
  target = '_self',
}) => {
  return (
    <LinkWrapper align={align} color={color} style={style}>
      {!path ? (
        <>
          {arrowPlacement === 'left' && (
            <ArrowWrapper className={className} arrowStyles={arrowStyles} color={arrowColor ?? color} placement="right">
              <DFArrow color={arrowColor ?? color} weight={4} size="small" direction="left" />
            </ArrowWrapper>
          )}
          <StyledButton onClick={onClick} styles={styles} customColor={color}>
            <Typography
              variant="bodyMedium"
              fontStyle={fontStyle ?? 'italic'}
              fontWeight={fontWeight}
              customColor={color}
              className={className}
              styles={css`
                line-height: 120%;
                ${fontStyles}
              `}
            >
              {children}
            </Typography>
          </StyledButton>
          {arrowPlacement === 'right' && (
            <ArrowWrapper className={className} arrowStyles={arrowStyles} color={arrowColor ?? color} placement="right">
              <DFArrow color={arrowColor ?? color} weight={4} size="small" direction="right" />
            </ArrowWrapper>
          )}
        </>
      ) : (
        <>
          {arrowPlacement === 'left' && (
            <ArrowWrapper className={className} arrowStyles={arrowStyles} color={arrowColor ?? color} placement="right">
              <DFArrow color={arrowColor ?? color} weight={4} size="small" direction="left" />
            </ArrowWrapper>
          )}
          <StyledNextLink color={color} href={path} onClick={onClick} styles={styles} target={target}>
            <Typography
              variant="bodyMedium"
              fontStyle={fontStyle ?? 'italic'}
              customColor={color}
              className={className}
              styles={css`
                line-height: 120%;
                ${fontStyles}
              `}
              fontWeight={fontWeight}
            >
              {children}
            </Typography>
          </StyledNextLink>
          {arrowPlacement === 'right' && (
            <ArrowWrapper className={className} arrowStyles={arrowStyles} color={arrowColor ?? color} placement="right">
              <DFArrow color={arrowColor ?? color} weight={4} size="small" direction="right" />
            </ArrowWrapper>
          )}
        </>
      )}
    </LinkWrapper>
  );
};

export default LinkButton;
