import axios from 'axios';
import { useRouter } from 'next/router';
import { FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import { accountActionTypes, useAccountDispatch, useAccountState } from '@/context/accountContext';
import { AuthorizationSteps, State, dialogActionTypes, useDialogDispatch } from '@/context/authorizationDialogContext';
import { AdyenPaymentResultCode } from '@/models/AdyenPaymentResultCode.enum';
import { IOfferIds } from '@/models/ILandingPage.interface';
import { IOfferItem } from '@/models/IOffer.interface';
import { LandingPage } from '@/payload-types';

import {
  AuthDialogStep,
  BuyOfferDialogStep,
  ForgotPasswordDialogStep,
  PaymentCancelDialog,
  PaymentDialogStep,
  PaymentHistoryDialogStep,
  ResetPasswordDialogStep,
} from './AuthorizationSteps';
import { ManageSubscriptionDialogStep } from './AuthorizationSteps/ManageSubscriptionDialogStep';

import { CancelSubscriptionDialog } from '../CancelSubscriptionDialog';
import { Dialog } from '../Dialog';

interface AuthorizationDialogProps {
  step: AuthorizationSteps | null;
  data: State['data'];
}

export const AuthorizationDialog: FC<AuthorizationDialogProps> = ({ step, data }) => {
  const { auth } = useAccountState();
  const { dispatch: accountDispatch } = useAccountDispatch();
  const dialogDispatch = useDialogDispatch();

  const [offers, setOffers] = useState<IOfferIds[]>([]);
  const [selectedOffer, setSelectedOffer] = useState<IOfferItem | null>(data?.selectedOffer ?? null);

  const nodeRef = useRef<HTMLDivElement>(null);
  const router = useRouter();

  const offerIds = data?.offerIds ?? offers;

  useEffect(() => {
    const fetchOfferIds = async () => {
      await axios
        .get<LandingPage>(
          `${process.env.NEXT_PUBLIC_PAYLOAD_URL}/api/globals/landing-page?locale=undefined&draft=false&depth=1s`
        )
        .then(({ data: { accessOfferIds } }) => {
          const mappedAccesOfferIds =
            accessOfferIds?.map((offer) => ({
              offerId: offer?.magineOfferId ?? '',
              id: offer?.id ?? '',
            })) ?? [];
          setOffers(mappedAccesOfferIds);
        });
    };
    fetchOfferIds();
  }, []);

  useEffect(() => {
    if (data?.selectedOffer) {
      setSelectedOffer(data.selectedOffer);
    }
  }, [data?.selectedOffer]);

  useEffect(() => {
    if (step === null) {
      setSelectedOffer(null);
    }
  }, [step]);

  const getSteps = useMemo<Record<AuthorizationSteps, { component: ReactNode; scroll?: 'body' | 'paper' }>>(
    () => ({
      [AuthorizationSteps.AUTH]: {
        component: (
          <AuthDialogStep
            selectedOffer={selectedOffer}
            initialOffer={data?.selectedOffer}
            onSelectOffer={setSelectedOffer}
            offerIds={offerIds ?? []}
            email={data?.email}
            error={data?.error}
            canBuyHavingOffer={data?.canBuyHavingOffer}
            skipOffers={data?.skipOffers}
          />
        ),
      },
      [AuthorizationSteps.FORGOT_PASSWORD]: {
        component: <ForgotPasswordDialogStep email={data?.email} />,
        scroll: undefined,
      },
      [AuthorizationSteps.RESET_PASSWORD]: {
        component: <ResetPasswordDialogStep />,
        scroll: undefined,
      },
      [AuthorizationSteps.BUY_OFFER]: {
        // typename={data?.typename},
        component: (
          <BuyOfferDialogStep
            offerIds={offerIds}
            initialOffer={data?.selectedOffer}
            selectedOffer={selectedOffer}
            onSelectOffer={setSelectedOffer}
          />
        ),
        scroll: 'body',
      },
      [AuthorizationSteps.PAYMENT_HISTORY]: {
        component: <PaymentHistoryDialogStep data={data} />,
        scroll: 'body',
      },
      [AuthorizationSteps.PAYMENT_CANCEL]: {
        component: <PaymentCancelDialog offerId={data?.offerId} />,
        scroll: undefined,
      },
      [AuthorizationSteps.MANAGE_SUBSCRIPTION]: {
        component: <ManageSubscriptionDialogStep />,
        scroll: undefined,
      },
      [AuthorizationSteps.CANCEL_SUBSCRIPTION]: {
        component: <CancelSubscriptionDialog offerId={data?.offerId} />,
        scroll: undefined,
      },
      [AuthorizationSteps.PAYMENT]: {
        component: (
          <PaymentDialogStep
            type={data?.type}
            result={data?.result}
            newOffer={selectedOffer || undefined}
            originalOfferId={!!data?.originalOfferId ? data.originalOfferId : undefined}
            promoCode={data?.promoCode}
          />
        ),
        scroll: 'body',
      },
    }),
    [data, offerIds, selectedOffer]
  );

  const handleClose = () => {
    if (step === null) {
      return;
    }

    if ([AuthorizationSteps.AUTH, AuthorizationSteps.BUY_OFFER].includes(step)) {
      accountDispatch({
        type: accountActionTypes.ERROR,
        payload: false,
      });
    }
    dialogDispatch({ type: dialogActionTypes.CLOSE_ALL });
    if (step === AuthorizationSteps.RESET_PASSWORD) {
      router.replace(window.location.pathname);
    }
    if (auth.token && ![AuthorizationSteps.BUY_OFFER, AuthorizationSteps.PAYMENT].includes(step)) {
      window.history.replaceState(null, '', window.location.pathname);
    }
  };

  return (
    <Dialog open={step !== null} onClose={handleClose} scroll={step ? getSteps[step]?.scroll : undefined}>
      <SwitchTransition>
        <CSSTransition
          key={step}
          nodeRef={nodeRef}
          addEndListener={(done) => {
            nodeRef.current?.addEventListener('transitionend', done, false);
          }}
          classNames="slide-and-fade"
        >
          <div ref={nodeRef}>
            <div className="transition-element">{step !== null && getSteps[step].component}</div>
          </div>
        </CSSTransition>
      </SwitchTransition>
    </Dialog>
  );
};
