import { Dispatch, SetStateAction } from 'react';

import { IOfferIds } from '@/models/ILandingPage.interface';
import { IOfferItem } from '@/models/IOffer.interface';
import { OfferType } from '@/models/OfferTypeName.enum';

export const enum LoginSteps {
  EMAIL,
  USER_EXISTS,
  REGISTRATION,
  LOGIN,
  SELECT_OFFER,
  PAYMENT,
}

export interface AuthDialogProps {
  email?: string;
  error?: string;
  offerIds: IOfferIds[];
  selectedOffer: IOfferItem | null;
  onSelectOffer: Dispatch<SetStateAction<IOfferItem | null>>;
  typename?: OfferType;
  initialOffer?: IOfferItem | null;
  canBuyHavingOffer?: boolean;
  skipOffers?: boolean;
}

export interface AuthForm {
  email: string;
  password: string;
  repeatPassword: string;
  terms: boolean;
  marketing: boolean;
}
