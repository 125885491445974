import styled from '@emotion/styled';
import { CSSProperties, FC } from 'react';

interface LogoIconProps {
  className?: string;
  color?: string;
  style?: CSSProperties;
}

const LogoWrapper = styled.div<{ customColor?: string }>`
  height: 100%;
  width: 70px;
  path {
    fill: ${({ customColor, theme }) => customColor ?? theme.palette.primary.main};
  }
`;
const LogoIcon: FC<LogoIconProps> = ({ color, style }) => {
  return (
    <LogoWrapper customColor={color} style={style}>
      <svg width="100%" height="100%" viewBox="0 0 200 101" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M154.938 100.802C155.496 88.8509 160.371 77.5077 168.658 68.8776C176.945 60.2475 188.081 54.9165 200 53.8739V100.802H154.938ZM103.221 100.802V0H199.991V47.0419H148.382V100.802H103.221ZM0 100.802V0H48.385C75.1083 0 96.7701 22.5659 96.7701 50.3997C96.7701 78.2336 75.1083 100.802 48.385 100.802H0Z"
          height="100%"
        />
      </svg>
    </LogoWrapper>
  );
};

export default LogoIcon;
