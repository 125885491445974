import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import NextImage from 'next/image';
import { FC, useMemo } from 'react';

import { useAccountState } from '@/context/accountContext';
import { dialogActionTypes, useDialogDispatch } from '@/context/authorizationDialogContext';
import { IOfferItem } from '@/models/IOffer.interface';
import { OffersStylesConfig, Page } from '@/payload-types';
import theme from '@/theme/theme';
import { loginCreateAccount } from '@/utils/analytics';

import { Button } from './Button/Button';
import { Typography } from './Typography';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 520,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.common.black,
    margin: theme.spacing(0, 0),

    [theme.breakpoints.up('smm')]: {
      width: 'calc(50% - 30px)',
      margin: theme.spacing(2, 2),
    },

    // [theme.breakpoints.up('mdl')]: {
    //   width: 'calc(33.33333% - 30px)',
    //   margin: theme.spacing(2, 3),
    // },
  },
  image: {
    position: 'relative',
    aspectRatio: '16 / 9',
    overflow: 'hidden',
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  header: {
    position: 'absolute',
    left: 20,
    right: 20,
    bottom: 0,
    zIndex: 200,
    textAlign: 'center',
  },
  title: {
    fontSize: 24,
  },
  priceInCents: {
    fontSize: 24,
  },

  content: {
    // padding: theme.spacing(2, 8, 8),
  },
  usplist: {
    padding: 0,
    listStyleType: '->',
  },
  usplistitem: {
    margin: theme.spacing(3, 0),
  },

  button: {
    color: '#FFFFFF',
    borderColor: '#FFFFFF',
  },
}));

const UspList = styled.ul`
  padding: 0;
  list-style-type: '';
  li {
    position: relative;
    padding-left: 2rem;
  }
  li:before {
    content: '->';
    color: #ec6446;
    font-size: 2.5rem;
    line-height: 0rem;
    position: absolute;
    top: 1.1rem;
    left: 0rem;
    font-weight: 300;
  }
`;

const ContentContainer = styled.div<{ backgroundColor?: string }>`
  padding: 7vw;
  background-color: ${({ backgroundColor, theme }) => backgroundColor ?? theme.palette.secondary.main};
  height: 100%;

  ${theme.breakpoints.up('sm')} {
    padding: 4rem 6rem;
  }
`;

interface OfferLargeProps {
  data: IOfferItem;
  offerStylesConfig?: OffersStylesConfig & { callToActionButton?: string };
  includeOfferIds?: Page['accessOfferIds'];
}

const OfferLarge: FC<OfferLargeProps> = ({ data, offerStylesConfig, includeOfferIds }) => {
  const classes = useStyles();
  const account = useAccountState();
  const dialogDispatch = useDialogDispatch();
  const theme = useTheme();

  const mappedIncludeOfferIds =
    includeOfferIds?.map((offer) => ({ offerId: offer.magineOfferId ?? '', id: offer.id as string | undefined })) ?? [];

  const handleSubscribe = () => {
    loginCreateAccount();
    dialogDispatch({
      type: dialogActionTypes.DIALOG_AUTH,
      payload: { selectedOffer: data, offerIds: mappedIncludeOfferIds },
    });
  };

  const handleActivateSubscription = async () => {
    dialogDispatch({
      type: dialogActionTypes.DIALOG_AUTH,
      payload: { selectedOffer: data, offerIds: mappedIncludeOfferIds },
    });
  };

  const offerImage = useMemo(() => {
    const offerMedia = offerStylesConfig?.offerMedia;

    if (offerMedia && typeof offerMedia !== 'string') {
      return offerMedia.sizes?.tablet?.url ?? offerMedia.sizes?.thumbnail?.url;
    }

    return;
  }, [offerStylesConfig, data]);

  return (
    <Box className={classes.root}>
      {offerImage && (
        <Box className={classes.image}>
          <img src={offerImage} width={400} height={571} className={classes.img} alt={data.title} />
        </Box>
      )}
      <ContentContainer backgroundColor={offerStylesConfig?.backgroundColor ?? theme.palette.primary.main}>
        <Box>
          <Typography
            customColor={offerStylesConfig?.textColor ?? theme.palette.primary.contrastText}
            variant="bodyLarge"
            fontWeight="500"
          >
            {data.title}
          </Typography>
          {data.priceInCents > 0 ? (
            <Typography
              customColor={offerStylesConfig?.textColor ?? theme.palette.primary.contrastText}
              variant="bodyLarge"
              fontStyle="italic"
            >
              {data.priceInCents / 100} {data.currency}
            </Typography>
          ) : (
            <Typography
              customColor={offerStylesConfig?.textColor ?? theme.palette.primary.contrastText}
              variant="bodyLarge"
              fontStyle="italic"
            >
              Gratis
            </Typography>
          )}
        </Box>
        <UspList>
          {data.usps.map((item: string, idx: number) => (
            <Box component="li" key={idx} className={classes.usplistitem}>
              <Box ml={2}>
                <Typography
                  customColor={offerStylesConfig?.textColor ?? theme.palette.primary.contrastText}
                  variant="bodySmall"
                  fontWeight="300"
                >
                  {item}
                </Typography>
              </Box>
            </Box>
          ))}
        </UspList>
        <Box mt="4rem" textAlign="center">
          <Box display="inline-block">
            {account?.auth.token ? (
              <Button color="white" size="small" onClick={handleActivateSubscription}>
                {offerStylesConfig?.callToActionButton || 'Aktivera nu'}
              </Button>
            ) : (
              <Button color="white" size="small" onClick={handleSubscribe}>
                {offerStylesConfig?.callToActionButton || 'Aktivera nu'}
              </Button>
            )}
          </Box>
        </Box>
      </ContentContainer>
    </Box>
  );
};

export default OfferLarge;
