import { Box, FormControl } from '@material-ui/core';
import axios from 'axios';
import { useRouter } from 'next/router';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import { Button } from '@/components/Button/Button';
import Payment from '@/components/Payment';
import { PromoCodeField } from '@/components/PromoCodeField';
import { SelectOffer } from '@/components/SelectOffer';
import { useAuthorizationDialog } from '@/components/_overlays/AuthorizationDialog/AuthorizationDialog.hooks';
import { SelectedOffer } from '@/components/_overlays/AuthorizationDialog/AuthorizationSteps/SelectedOffer';
import { accountActionTypes, useAccountDispatch, useAccountState } from '@/context/accountContext';
import { dialogActionTypes, useDialogDispatch } from '@/context/authorizationDialogContext';
import { useMountEffect } from '@/hooks/useMountEffect';
import { IOfferItem } from '@/models/IOffer.interface';
import { subscriptionTermsApproval } from '@/utils/analytics';

import EmailStep from './steps/EmailStep';
import LoginStep from './steps/LoginStep';
import RegistrationStep from './steps/RegistrationStep';
import SelectOfferStep from './steps/SelectOfferStep';

import { Error, InfoHeader, InfoText, InfoWrapper, InfoWrapperButtons } from '../AuthorizationDialog.styles';
import { AuthDialogProps, AuthForm, LoginSteps } from '../AuthorizationDialog.types';

export const AuthDialogStep: FC<AuthDialogProps> = ({
  email = '',
  error,
  offerIds,
  initialOffer,
  selectedOffer,
  onSelectOffer,
  canBuyHavingOffer,
  skipOffers,
}) => {
  const router = useRouter();
  const emailQuery = router.query.email as string;
  const [promoCode, setPromoCode] = useState<string>((router.query.code as string) || '');
  const [invalidPromoCode, setInvalidPromoCode] = useState<boolean>();
  const [_error, _setError] = useState<string>();
  const [reducedPrice, setReducedPrice] = useState<number>();

  const [promoCodeOffer, setPromoCodeOffer] = useState<IOfferItem | null>(null);
  const [promoOfferExpectedCost, setPrOfferExpectedCost] = useState<IOfferItem | null>(null);
  const { dispatch: accountDispatch } = useAccountDispatch();

  const nodeRef = useRef<HTMLDivElement>(null);

  const dialogDispatch = useDialogDispatch();
  const { auth } = useAccountState();

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<AuthForm>({
    defaultValues: { email: emailQuery, password: '', repeatPassword: '', terms: false, marketing: false },
  });

  const { validatePromoCode, logInUser, registerUser, step, setStep, hasOffer } = useAuthorizationDialog({
    emailQuery,
    auth,
    canBuyHavingOffer,
    skipOffers,
  });

  const updateReducedPrice = async (code: string, offerId: string) => {
    try {
      const { data } = await axios.post('/api/promo-code', {
        token: auth.token,
        offerId,
        code,
      });
      if (data) {
        setReducedPrice(data.reducedPrice);
      } else {
        setInvalidPromoCode(true);
        return false;
      }
    } catch {
      setReducedPrice(undefined);
    }
  };

  const handleValidatePromoCode = async () => {
    if (!promoCode || !selectedOffer?.id) {
      return false;
    }

    const promoCodeStatus = await validatePromoCode(
      selectedOffer.id === 'pass-offer' ? offerIds[0].offerId : selectedOffer.id,
      promoCode
    );

    if (promoCodeStatus?.status === 'valid') {
      updateReducedPrice(promoCode, selectedOffer.id);
      return true;
    } else if (promoCodeStatus?.status === 'wrong-offer' && promoCodeStatus.offer) {
      setPromoCodeOffer(promoCodeStatus.offer);
      setInvalidPromoCode(true);
      updateReducedPrice(promoCode, promoCodeStatus.offer.id);
      return false;
    } else {
      setInvalidPromoCode(true);
      return false;
    }
  };

  const formEmail = getValues('email');

  // Remove query params when auth dialog is open
  useMountEffect(() => {
    if (!window.location.search) {
      return;
    }

    if (promoCode) {
      validatePromoCode('NOT_AN_OFFER', promoCode).then((validation) => {
        if (validation?.status === 'wrong-offer' && validation.offer) {
          onSelectOffer(validation.offer);
        }
      });
    }
    const params = new URLSearchParams(window.location.search);
    params.delete('email');
    params.delete('code');
    window.history.replaceState(null, '', `${window.location.pathname}?${params.toString()}`);
  });

  // Reset promo code error on step change
  useEffect(() => {
    if (step) {
      setInvalidPromoCode(undefined);
      accountDispatch({ type: accountActionTypes.ERROR, payload: false });
    }
  }, [step, accountDispatch]);

  const goToPreviousStep = () => {
    setStep((prevState) => --prevState);
  };

  const handleProcessForm = async (data: AuthForm) => {
    if (step === LoginSteps.EMAIL) {
      try {
        const result = await axios.post('/api/auth/users/lookup', { email: data.email });
        setStep(result.data ? LoginSteps.LOGIN : LoginSteps.REGISTRATION);
      } catch {
        _setError('Unknown error. Please try again.');
      }
    }
    if (step === LoginSteps.USER_EXISTS) {
      if (auth.openId && promoCode) {
        const promoCodeValid = await handleValidatePromoCode();
        if (!promoCodeValid) {
          return;
        }
      }
      setStep(auth.openId ? LoginSteps.PAYMENT : LoginSteps.LOGIN);
    }
    if (step === LoginSteps.LOGIN) {
      await logInUser(data.email, data.password);
    }
    if (step === LoginSteps.SELECT_OFFER) {
      if (promoCode) {
        const promoCodeValid = await handleValidatePromoCode();
        if (!promoCodeValid) {
          return;
        }
      }
      setStep(LoginSteps.PAYMENT);
    }
    if (step === LoginSteps.REGISTRATION) {
      subscriptionTermsApproval(true);
      await registerUser(data.email, data.password, data.marketing);
    }
  };

  const handleForgotPassword = () => {
    dialogDispatch({ type: dialogActionTypes.DIALOG_FORGOTPASSWORD, payload: { email: formEmail } });
  };

  const formButtonText = useMemo(() => {
    switch (step) {
      case LoginSteps.REGISTRATION:
        return 'Skapa konto';
      case LoginSteps.LOGIN:
        return 'Logga in';
      default:
        return 'Gå vidare';
    }
  }, [step]);

  const offerSelectable = [LoginSteps.USER_EXISTS, LoginSteps.SELECT_OFFER].includes(step) && !skipOffers;

  const shouldShowSelectedOffer =
    ![LoginSteps.EMAIL, LoginSteps.LOGIN, LoginSteps.REGISTRATION].includes(step) && !skipOffers;

  const handleAcceptPromoCodeOffer = async () => {
    await updateReducedPrice(promoCode, promoCodeOffer!.id);
    onSelectOffer(promoCodeOffer);
    setPromoCodeOffer(null);
    setInvalidPromoCode(false);
    setStep(LoginSteps.PAYMENT);
  };

  const handleContinueWithoutPromoCode = handleSubmit(() => {
    setPromoCode('');
    setReducedPrice(undefined);
    setPromoCodeOffer(null);
    setInvalidPromoCode(false);
    setStep(LoginSteps.PAYMENT);
  });

  const handleGoBackToSelect = () => {
    setPromoCodeOffer(null);
    setInvalidPromoCode(false);
  };

  return (
    <>
      {promoCodeOffer && (
        <InfoWrapper>
          <InfoHeader align="left" variant="body2">
            Kampanjkod gäller inte vald prenumeration
          </InfoHeader>
          <InfoText align="left" variant="body2">
            Du har en kampanjkod som gäller för <strong>{promoCodeOffer.title}</strong> men har valt{' '}
            {selectedOffer ? <strong>{selectedOffer.title}</strong> : 'inte valt en prenumeration'}
            . <br />
            <br />
            Klicka på "Fortsätt med kampanjkod" för att starta <strong>{promoCodeOffer.title}</strong> för{' '}
            <strong>
              {(reducedPrice ?? promoCodeOffer.priceInCents) / 100} {promoCodeOffer.currency}
            </strong>{' '}
            och använda din kampanjkod
            {selectedOffer ? (
              <>
                , eller välj "Fortsätt med vald prenumeration" för att starta <strong>{selectedOffer.title}</strong> för{' '}
                <strong>
                  {selectedOffer.priceInCents / 100} {selectedOffer.currency}
                </strong>
              </>
            ) : (
              ''
            )}
            .
          </InfoText>
          <InfoWrapperButtons>
            {selectedOffer && (
              <>
                <Button size="large" color="red" onClick={handleAcceptPromoCodeOffer}>
                  Fortsätt med kampanjkod
                </Button>
                <Button size="large" color="white" onClick={handleContinueWithoutPromoCode}>
                  Fortsätt med vald prenumeration
                </Button>
              </>
            )}
            <Button color="white" size="large" onClick={handleGoBackToSelect}>
              Tillbaka
            </Button>
          </InfoWrapperButtons>
        </InfoWrapper>
      )}
      {(error ?? _error) && (
        <Box mb={2} mt={4} display="flex" flexDirection="column" alignItems="center">
          <Error variant="body2" color="error">
            {error ?? _error}
          </Error>
        </Box>
      )}
      {!promoCodeOffer && (
        <form autoComplete="off" onSubmit={handleSubmit(handleProcessForm)} noValidate>
          {offerSelectable ? (
            <SelectOffer
              initialOffer={initialOffer}
              includedIds={offerIds}
              selectedOffer={selectedOffer}
              onSelectedChange={onSelectOffer}
            />
          ) : (
            !hasOffer && shouldShowSelectedOffer && <SelectedOffer offer={selectedOffer} reducedPrice={reducedPrice} />
          )}
          <Box mb={3}>
            {offerSelectable && (
              <Box mb={10}>
                <PromoCodeField onSetValue={setPromoCode} promoCode={promoCode} error={!!invalidPromoCode} />
              </Box>
            )}
            <Box mt={10}>
              <SwitchTransition>
                <CSSTransition
                  key={step}
                  nodeRef={nodeRef}
                  addEndListener={(done) => {
                    nodeRef.current?.addEventListener('transitionend', done, false);
                  }}
                  classNames="slide-and-fade"
                >
                  <div ref={nodeRef}>
                    <div className="transition-element">
                      {step === LoginSteps.EMAIL && <EmailStep control={control} email={email} errors={errors} />}
                      {(step === LoginSteps.USER_EXISTS || step === LoginSteps.SELECT_OFFER) && (
                        <SelectOfferStep formEmail={formEmail} auth={auth} goToPreviousStep={goToPreviousStep} />
                      )}
                      {step === LoginSteps.LOGIN && (
                        <LoginStep
                          auth={auth}
                          formEmail={formEmail}
                          setStep={setStep}
                          control={control}
                          errors={errors}
                          handleForgotPassword={handleForgotPassword}
                        />
                      )}
                      {step === LoginSteps.REGISTRATION && (
                        <RegistrationStep control={control} getValues={getValues} errors={errors} />
                      )}
                      {step === LoginSteps.PAYMENT && <Payment offerId={selectedOffer?.id} promoCode={promoCode} />}
                    </div>
                  </div>
                </CSSTransition>
              </SwitchTransition>
            </Box>
          </Box>
          <FormControl fullWidth margin="normal">
            {step !== LoginSteps.PAYMENT && (
              <Button size="large" color="red" disabled={isSubmitting} data-testid="login-form-submit-button">
                {formButtonText}
              </Button>
            )}
          </FormControl>
        </form>
      )}
    </>
  );
};
