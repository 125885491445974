import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ImgHTMLAttributes, useMemo } from 'react';

import { Typography } from '@/components/Typography';
import { IAuthUser } from '@/models/IAuthUser.interface';
import { HalfImageHalfTextSectionBlock as HalfImageHalfTextSectionBlockData } from '@/payload-types';
import { mqMax } from '@/styles/breakpoints';

export type HalfImageHalfTextSectionBlockProps = {
  block: HalfImageHalfTextSectionBlockData;
  clientIp?: string;
  authUser?: IAuthUser;
};

const BlockWrapper = styled.div<{ textSide?: 'left' | 'right' }>`
  margin: 0 auto;
  display: flex;
  flex-direction: ${({ textSide }) => (textSide === 'right' ? 'row' : 'row-reverse')};
  justify-content: space-between;
  max-width: 1920px;
  padding: 4%;
  align-content: center;
  align-items: center;
  > * {
    padding: 0 4%;
    width: 50%;
  }
  ${mqMax[3]} {
    flex-direction: column-reverse;
    gap: 40px;
    > * {
      padding: 0;
      width: 100%;
    }
  }
`;

// {
//     "blockType": "HalfImageHalfTextSectionBlock",
//     "textSide": "left",
//     "sideMedia": {
//         "id": "6763493ceff41d212b8190dd",
//         "filename": "Frame 1000.png",
//         "mimeType": "image/png",
//         "filesize": 260042,
//         "width": 2292,
//         "height": 1296,
//         "focalX": 50,
//         "focalY": 50,
//         "sizes": {
//             "thumbnail": {
//                 "width": 400,
//                 "height": 300,
//                 "mimeType": "image/png",
//                 "filesize": 34992,
//                 "filename": "Frame 1000-400x300.png",
//                 "url": "http://localhost:4000/api/media/file/Frame 1000-400x300.png"
//             },
//             "card": {
//                 "width": 768,
//                 "height": 1024,
//                 "mimeType": "image/png",
//                 "filesize": 133790,
//                 "filename": "Frame 1000-768x1024.png",
//                 "url": "http://localhost:4000/api/media/file/Frame 1000-768x1024.png"
//             },
//             "tablet": {
//                 "width": 1024,
//                 "height": 579,
//                 "mimeType": "image/png",
//                 "filesize": 90053,
//                 "filename": "Frame 1000-1024x579.png",
//                 "url": "http://localhost:4000/api/media/file/Frame 1000-1024x579.png"
//             }
//         },
//         "createdAt": "2024-12-18T22:14:20.893Z",
//         "updatedAt": "2024-12-18T22:14:20.893Z",
//         "url": "http://localhost:4000/api/media/file/Frame%201000.png",
//         "thumbnailURL": "http://localhost:4000/api/media/file/Frame%201000-400x300.png"
//     },
//     "title": {
// "titleText": "Biljetten direkt i appen"
//     },
//     "paragraph": {
//         "paragraphText": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris "
//     },
//     "id": "676349299f568153d34996ac"
// }

const TextPart = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  flex-grow: 1;
  flex-shrink: 0;
  ${mqMax[3]} {
    gap: 16px;
  }
`;
//
const MediaPart = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  img {
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
  }
`;

export default function HalfImageHalfTextSectionBlock({ block }: HalfImageHalfTextSectionBlockProps) {
  const imgProps = useMemo<ImgHTMLAttributes<HTMLImageElement>>(() => {
    if (typeof block.sideMedia === 'string') {
      return { src: block.sideMedia };
    }

    const { sizes, srcSet } = block.sideMedia.sizes
      ? Object.values(block.sideMedia.sizes).reduce(
          (style, media) => {
            if (media.width) {
              style.sizes.push(`${media.width}px`);
              style.srcSet.push(`${media.url} ${media.width}w`);
            }
            return style;
          },
          { sizes: [] as string[], srcSet: [] as string[] }
        )
      : { sizes: undefined, srcSet: undefined };

    return {
      alt: block.sideMedia.alt || undefined,
      src: block.sideMedia.sizes?.card?.url || undefined,
      sizes: sizes?.join(', '),
      srcSet: srcSet?.join(', '),
      // width: block.sideMedia.width || undefined,
    };
  }, [block.sideMedia]);
  return (
    <BlockWrapper textSide={block.textSide!}>
      <TextPart>
        <Typography variant="headlineXSmall" fontWeight="700" fontStyle="italic" customColor={block.title?.color!}>
          {block.title?.titleText}
        </Typography>
        {block.paragraph && (
          <Typography variant="bodyLarge" fontWeight="300" fontStyle="italic" customColor={block.paragraph.color!}>
            {block.paragraph.paragraphText}
          </Typography>
        )}
      </TextPart>
      <MediaPart>
        <img {...imgProps} />
      </MediaPart>
    </BlockWrapper>
  );
}
// {/* <TextPart>
// <Typography></>
// </TextPart>
// <MediaPart></MediaPart> */}
