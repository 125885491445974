import ButtonBlack from '@/assets/icons/play_button_black.svg';

const playButtonStyle = {
  width: '100%',
  height: '100%',
};
const PlayIcon = ({ color = '#0F0F0F' }: { color?: string }) => {
  return (
    <svg style={playButtonStyle} width="11" height="16" viewBox="0 0 11 16" fill="none">
      <path d="M11 8.19512L0 0V16L11 8.19512Z" fill={color} />
    </svg>
  );
};

export default PlayIcon;
