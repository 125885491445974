import { createTheme } from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    smm: true;
    mdl: true;
  }
}

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    cssFilters: PaletteFilters;
  }
  interface PaletteOptions {
    cssFilters: PaletteFilters;
  }
}

const dfPalette = {
  common: {
    black: '#000000',
    white: '#FFFFFF',
  },
  text: {
    primary: '#ffffff',
    secondary: '#222',
  },
  primary: {
    main: '#EC6446',
    light: '#fcdee3',
  },
  secondary: {
    main: '#569979',
  },
  background: {
    default: '#0f0f0f',
  },
  error: {
    main: '#EC6446',
  },
  action: {
    disabledBackground: '#424242',
    disabled: '#000000',
  },
};

type PaletteFilters = typeof dfPalette;

const theme = createTheme({
  typography: {
    htmlFontSize: 10,
    fontFamily: 'SunsetGothicPro',

    fontSize: 16,
    h1: {
      fontFamily: 'SunsetGothicPro',
      fontWeight: 500,
      fontSize: 50,
    },
    h2: {
      fontFamily: 'SunsetGothicPro',
      fontSize: 42,
    },
    h3: {
      fontSize: 20,
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontFamily: 'SunsetGothicPro',
      fontSize: 16,
      lineHeight: 1.5,
    },
    caption: {
      fontFamily: 'SunsetGothicPro',
      fontSize: 13,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      smm: 800,
      md: 1024,
      mdl: 1280,
      lg: 1440,
      xl: 1920,
    },
  },
  palette: {
    ...dfPalette,
    // These css filters were pregenerated with `hex-to-css-filter`, which doesn't support hydration because
    // of differences between server and browser.
    cssFilters: {
      common: {
        black: 'invert(0%) sepia(50%) saturate(7446%) hue-rotate(5deg) brightness(77%) contrast(89%);',
        white: 'invert(100%) sepia(0%) saturate(0%) hue-rotate(80deg) brightness(100%) contrast(102%);',
      },
      text: {
        primary: 'invert(84%) sepia(97%) saturate(37%) hue-rotate(66deg) brightness(113%) contrast(99%);',
        secondary: 'invert(12%) sepia(8%) saturate(15%) hue-rotate(360deg) brightness(107%) contrast(98%);',
      },
      primary: {
        main: 'invert(50%) sepia(53%) saturate(2761%) hue-rotate(335deg) brightness(99%) contrast(87%);',
        light: 'invert(100%) sepia(91%) saturate(1056%) hue-rotate(258deg) brightness(106%) contrast(97%);',
      },
      secondary: {
        main: 'invert(66%) sepia(14%) saturate(1107%) hue-rotate(99deg) brightness(80%) contrast(79%);',
      },
      background: {
        default: 'invert(0%) sepia(94%) saturate(2638%) hue-rotate(114deg) brightness(91%) contrast(88%);',
      },
      error: {
        main: 'invert(50%) sepia(53%) saturate(2761%) hue-rotate(335deg) brightness(99%) contrast(87%);',
      },
      action: {
        disabledBackground: 'invert(25%) sepia(0%) saturate(637%) hue-rotate(139deg) brightness(96%) contrast(94%);',
        disabled: 'invert(0%) sepia(50%) saturate(7446%) hue-rotate(5deg) brightness(77%) contrast(89%);',
      },
    },
  },
  spacing: 5,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        a: {
          textDecoration: 'none',
          color: '#EC6446',

          '&:hover': {
            /* textDecoration: 'underline', */
          },
        },
        html: {
          backgroundColor: '#0f0f0f',
        },
        body: {
          padding: '0 !important',
        },
        '::selection': {
          color: '#ffffff',
          backgroundColor: '#EC6446',
        },
      },
    },
    MuiContainer: {
      root: {
        maxWidth: 2030,
        margin: '0 auto',
      },
    },
    MuiPaper: {
      root: {
        color: '#FFFFFF',
      },
    },
    MuiRadio: {
      root: {
        color: '#FFFFFF',
      },
    },
    MuiOutlinedInput: {
      root: {
        color: '#FFFFFF',
        borderColor: '#000000',
        backgroundColor: '#535353',
        borderRadius: 25,
        padding: '12px 16px',
        boxShadow: 'none',
      },
      inputMarginDense: {
        paddingTop: 0,
        paddingBottom: 0,
      },
      input: {
        padding: 0,
      },
      notchedOutline: {
        borderColor: '#000000',
        '&:hover': {
          borderColor: '#000000',
        },
      },
    },
    MuiIconButton: {
      root: {
        color: '#ffffff',
        boxShadow: 'none',
        fontSize: 16,
        padding: '4px',
        '&:hover': {
          boxShadow: 'none',
        },
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#FFFFFF',
        margin: '4rem 0',
      },
    },
    MuiButton: {
      root: {
        fontSize: 16,
        boxShadow: 'none',
        fontWeight: 300,
        borderRadius: '50px',
        fontFamily: 'SunsetGothicPro',
        color: '#ffffff',
        textTransform: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
      contained: {
        backgroundColor: '#000000',
        boxShadow: 'none',
        color: '#ffffff',
        border: '1px solid #ffffff',
        '&:hover': {
          backgroundColor: '#1f1f1f',
          boxShadow: 'none',
        },
      },
      outlined: {
        color: '#000000',
        border: '1px solid #000000',
        boxShadow: 'none',
        fontWeight: 300,
        fontFamily: 'SunsetGothicPro',
        textTransform: 'uppercase',
        '&:hover': {
          boxShadow: 'none',
          color: '#ffffff',
          mixBlendMode: 'multiply',
          border: '1px solid #000000',
          backgroundColor: '#000000',
        },
      },
      outlinedSizeLarge: {
        padding: '12px 30px',
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
      containedSizeLarge: {
        padding: '15px 30px',
        height: '50px',
        fontSize: 16,
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.45)',
      },
    },
    MuiPickersClockNumber: {
      clockNumber: {
        color: '#222',
      },
    },
    MuiPickersDay: {
      day: {
        color: '#222',
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: '#0F0F0F',
      },
    },
    MuiCheckbox: {
      root: { color: '#FFFFFF' },
    },
  },
});

// theme.overrides = {
//   MuiDrawer: {
//     paper: {
//       backgroundColor: theme.palette.primary.main, // reference primary color
//     },
//   },
// };

export default theme;
