import { useEffect, useState } from 'react';

import { PopulatedOfferSettings, getSettingsData } from '@/endpoints/endpoints';
import { Setting } from '@/payload-types';

export const useSettingsData = () => {
  const [settingsData, setSettingsData] = useState<PopulatedOfferSettings | null>(null);

  useEffect(() => {
    getSettingsData()
      .then((response) => {
        setSettingsData(response.data);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }, []);

  return {
    settingsData,
    subscriptionOffers: settingsData?.subscriptionOffers,
    passOffers: settingsData?.passOffers,
    specialOffer: settingsData?.specialOffer,
  };
};
