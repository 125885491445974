import { Box } from '@material-ui/core';
import React, { FC, useMemo, useState } from 'react';

import LinkButton from '@/components/Button/LinkButton';
import { SubscriptionEnum, formatPrice, getBackgroundColor } from '@/components/Subscription/utils';
import { Typography } from '@/components/Typography';
import { useAccountState } from '@/context/accountContext';
import { dialogActionTypes, useDialogDispatch, useDialogState } from '@/context/authorizationDialogContext';
import { useSubscriptionData } from '@/context/subscription/subscription.provider';
import { useOffers } from '@/hooks/useOffers';
import { useSettingsData } from '@/hooks/useSettingsData';
import { IOfferItem } from '@/models/IOffer.interface';
import { PaymentType } from '@/models/Payment.enum';
import { OffersStylesConfig } from '@/payload-types';
import { getRecurringPeriodUnit } from '@/utils/time';

import { BuyOfferDialogStep } from './BuyOfferDialogStep';

interface ManageSubscriptionDialogStepProps {}

export const ManageSubscriptionDialogStep: FC<ManageSubscriptionDialogStepProps> = ({}) => {
  const { auth } = useAccountState();
  const { replacements } = useOffers(auth.token);
  const { subscriptionOffers } = useSettingsData();
  const { data } = useDialogState();

  const dialogDispatch = useDialogDispatch();
  const { reactivate, subscriptionData } = useSubscriptionData();

  const [selectedOffer, setSelectedOffer] = useState<IOfferItem | null>(null);
  const entitlement = subscriptionData?.data.viewer.entitlements.edges.find((e) => e.node.offerId === data?.offerId);

  const filteredOffers = useMemo(
    () =>
      replacements
        .filter((offer) => entitlement?.node.offerId !== offer.node.id)
        .filter((offer) =>
          subscriptionOffers?.find((so) => (so as OffersStylesConfig).magineOfferId === offer.node.id)
        ),
    [replacements, subscriptionOffers]
  );

  const hasCancelledSubscription = useMemo(() => entitlement?.node.status === 'cancelled', [entitlement]);

  const updateSubscription = async () => {
    dialogDispatch({
      type: dialogActionTypes.DIALOG_PAYMENT,
      payload: { type: PaymentType.SavePayment },
    });
  };

  const cancleSubscription = async (offerId: string) => {
    dialogDispatch({
      type: dialogActionTypes.DIALOG_CANCEL_SUBSCRIPTION,
      payload: { offerId },
    });
  };

  return (
    <>
      <Box mb="2rem">
        <Typography variant="titleLarge" fontWeight="500">
          Hantera din prenumeration
        </Typography>
      </Box>
      <Typography variant="bodySmall" fontWeight="300">
        Se din prenumerationsstatus nedan och välj en annan prenumeration om du vill ändra den.
      </Typography>

      <Box p="4rem" mt="4rem" style={{ background: getBackgroundColor(SubscriptionEnum.MONTHLY) }}>
        <Box>
          <Typography variant="bodyMedium" fontWeight="300">
            Prenumerationsstatus:
          </Typography>
          <Box mt="1rem" mb="1rem">
            <Typography variant="titleMedium" fontWeight="500">
              {entitlement?.node.offer.title}
            </Typography>
          </Box>
          <Typography variant="bodyExtraLarge" fontWeight="300" fontStyle="italic">
            {formatPrice(entitlement?.node?.offer.priceInCents)} SEK
            {getRecurringPeriodUnit(entitlement?.node?.offer.recurringPeriod?.unit)}
          </Typography>
        </Box>
      </Box>

      <Box mt="1rem" mb="2rem" display="flex" flexDirection="column" alignItems="end">
        <LinkButton fontWeight="300" onClick={() => updateSubscription()}>
          Uppdatera betaluppgifter
        </LinkButton>

        {hasCancelledSubscription ? (
          <LinkButton fontWeight="300" onClick={() => reactivate(entitlement?.node?.offerId ?? '')}>
            Återta prenumeration
          </LinkButton>
        ) : (
          <LinkButton fontWeight="300" onClick={() => cancleSubscription(entitlement?.node?.offerId ?? '')}>
            Avsluta prenumeration
          </LinkButton>
        )}
      </Box>

      <Box mt="5rem" mb="1rem">
        <Typography variant="bodyMedium" fontWeight="300" fontStyle="italic">
          Andra prenumerationer att välja mellan:
        </Typography>
      </Box>

      {!!filteredOffers && filteredOffers?.length > 0 && (
        <BuyOfferDialogStep
          originalOfferId={entitlement?.node.offerId}
          selectedOffer={selectedOffer}
          initialOffer={data?.selectedOffer}
          onSelectOffer={setSelectedOffer}
          offerIds={filteredOffers.map((o) => ({ offerId: o.node.id }))}
        />
      )}
    </>
  );
};
