import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';

import { getSettingsData } from '@/endpoints/endpoints';
import { IOffer, IReplacementItem, OfferEdges } from '@/models/IOffer.interface';
import { OffersStylesConfig } from '@/payload-types';
import { PayloadCollectionResponse } from '@/utils/typescript';

export const useOffers = (token?: string | null) => {
  const [offers, setOffers] = useState<OfferEdges[]>([]);
  const [replacements, setReplacements] = useState<IReplacementItem[]>([]);
  const [offersStylesConfig, setOffersStylesConfig] = useState<OffersStylesConfig[] | null>(null);

  const getOffersStylesConfig = useCallback(() => {
    axios
      .get<
        PayloadCollectionResponse<OffersStylesConfig>
      >(`${process.env.NEXT_PUBLIC_PAYLOAD_URL}/api/offers-styles-config`)
      .then((response) => {
        setOffersStylesConfig(response.data.docs);
      });
  }, []);

  const getOffers = useCallback(async () => {
    const settingsData = await getSettingsData();
    const offers = await axios.get<IOffer>(`/api/offers/${!!token ? token : null}`);

    const validOfferIds = settingsData.data.subscriptionOffers?.map(
      (offer) => (offer as OffersStylesConfig).magineOfferId
    );
    const validOffers = offers.data.data.viewer.offers.edges.filter((offer) => validOfferIds?.includes(offer.node.id));

    setOffers(validOffers);
    if (!!token) {
      setReplacements(offers.data.data.viewer.entitlements.edges[0].node.replacements.edges);
    }
  }, [token]);

  useEffect(() => {
    getOffers();
    getOffersStylesConfig();
  }, [getOffers, getOffersStylesConfig]);

  return { offers, offersStylesConfig, replacements };
};
