import styled from '@emotion/styled';
import { CSSProperties, useMemo } from 'react';

type DFArrowProps = {
  weight?: number | 'light' | 'semibold';
  size?: 'auto' | 'small' | 'medium' | 'large';
  direction?: 'left' | 'right' | 'up' | 'down';
  color?: string;
};

const largeArrowPaths = [
  'M0 34L90 34',
  'M49 1C51.8952 19.697 69.1731 34 90 34',
  'M49 67C51.8952 48.303 69.1731 34 90 34',
] as [string, string, string];

const mediumArrowPaths = [
  'M0 6.5L16 6.5',
  'M9 1C9.48301 4.11616 12.3654 6.5 15.84 6.5',
  'M9 12C9.48301 8.88384 12.3654 6.5 15.84 6.5',
] as [string, string, string];

const smallArrowPaths = [
  'M0 3.3811L9.66183 3.3811',
  'M6 0.164551C6.28246 1.98688 7.96811 3.38095 10 3.38095',
  'M6 6.5975C6.28246 4.77517 7.96811 3.3811 10 3.3811',
] as [string, string, string];

const ArrowSvg = styled.svg<{ color?: string; hoverColor?: string; direction: DFArrowProps['direction'] }>`
  display: inline-block;
  objectfit: contain;
  height: 100%;
  width: auto;
  stroke: ${({ color }) => color};
  &:hover {
    ${({ hoverColor }) => (hoverColor ? `stroke: ${hoverColor};` : '')}
  }
  ${({ direction }) => {
    switch (direction) {
      case 'up':
        return 'transform: rotate(270deg);';
      case 'down':
        return 'transform: rotate(90deg);';
      case 'left':
        return 'transform: rotate(180deg);';
      case 'right':
        return 'transform: rotate(0deg);';
    }
  }}
`;

export default function DFArrow({
  weight = 'light',
  size = 'auto',
  direction = 'right',
  color = '#fff',
}: DFArrowProps) {
  let strokeWidth = typeof weight === 'number' ? weight : weight === 'semibold' ? 4 : 2;
  let width: string | number = 'auto';
  let height: string | number = '100%';
  const isVertical = direction === 'up' || direction === 'down';
  // We use the different paths for the different sizes, because it makes scaling look nicer.
  let paths = largeArrowPaths;

  switch (size) {
    case 'small':
      width = 10;
      height = 7;
      paths = smallArrowPaths;
      strokeWidth = strokeWidth / (90 / 10);
      break;
    case 'medium':
      width = 16;
      height = 13;
      paths = mediumArrowPaths;
      strokeWidth = strokeWidth / (90 / 16);
      break;
    case 'large':
      width = 90;
      height = 68;
      break;
    case 'auto':
      width = 90;
      height = 68;
      break;
  }

  const [stem, arrowHeadA, arrowHeadB] = paths;

  return (
    <ArrowSvg
      direction={direction}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d={stem} stroke={color} strokeWidth={strokeWidth} strokeMiterlimit="10" />
      <path d={arrowHeadA} stroke={color} strokeWidth={strokeWidth} strokeMiterlimit="10" />
      <path d={arrowHeadB} stroke={color} strokeWidth={strokeWidth} strokeMiterlimit="10" />
    </ArrowSvg>
  );
}
