import { Box } from '@material-ui/core';
import isPropValid from '@emotion/is-prop-valid';
import { media } from '@/styles';
import styled from '@emotion/styled';

export const Checkmark = styled.div`
  display: none;
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
`;

interface WrapperProps {
  color?: string;
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  background-color: ${({ color }) => color ?? 'transparent'};
  color: ${({ theme }) => theme.palette.common.white};

  position: relative;
  text-align: center;
  max-width: 620px;
`;

export const Label = styled.label`
  cursor: pointer;
  padding: 4rem;
`;

export const Input = styled.input`
  display: none;

  &:checked + ${Wrapper} {
    box-shadow: 2px 4px 15px 5px rgba(0, 0, 0, 0.4);
    border: 1px solid ${({ theme }) => theme.palette.text.primary};

    & ${Checkmark} {
      display: block;
    }
  }
`;

export const Header = styled.div<{ expanded: boolean }>`
  display: flex;
  flex-direction: column;
  height: ${({ expanded }) => (expanded ? '100%' : 'unset')};
`;

export const Title = styled.span`
  font-size: 16px;
  padding: ${({ theme }) => theme.spacing(0, 10)};

  ${media.md} {
    font-size: 24px;
    margin-bottom: 5px;
  }
`;

export const Price = styled(Box, { shouldForwardProp: isPropValid })<{ isDiscounted?: boolean }>`
  font-size: ${({ isDiscounted }) => (isDiscounted ? '11px' : '18px')};
  font-weight: bold;
  text-decoration: ${({ isDiscounted }) => (isDiscounted ? 'line-through' : 'unset')};

  ${media.md} {
    font-size: ${({ isDiscounted }) => (isDiscounted ? '14px' : '24px')};
  }
`;

export const PriceWrapper = styled.div`
  display: block;
  grid-template-columns: repeat(2, auto);
  gap: ${({ theme }) => theme.spacing(2)}px;
  align-items: baseline;
`;

export const UspList = styled.ul`
  list-style: none;
  text-align: left;
  padding: ${({ theme }) => theme.spacing(0, 1)};
  margin: 0;

  ${media.md} {
    padding: ${({ theme }) => theme.spacing(0, 3)};
    margin: revert;
  }
`;

export const UspListItem = styled.li`
  letter-spacing: 0.5px;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin: ${({ theme }) => theme.spacing(1, 0)};

  ${media.md} {
    margin: ${({ theme }) => theme.spacing(2, 0)};
  }
`;
