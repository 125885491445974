import styled from '@emotion/styled';
import { FC, useCallback, useMemo } from 'react';

import OfferLarge from '@/components/OfferLarge';
import { useOffers } from '@/hooks/useOffers';
import { Page, SubscriptionOffers } from '@/payload-types';
import { mqMax } from '@/styles/breakpoints';
import { isOffer } from '@/utils/typescript';

interface SubscriptionOffersBlockProps {
  block: SubscriptionOffers;
  includeOfferIds: Page['accessOfferIds'];
  hasSubscriptionForThisPage?: boolean;
}

const OffersContainer = styled.div`
  margin: 8rem auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 2rem;

  ${mqMax[4]} {
    margin: 2rem auto;
  }

  ${mqMax[5]} {
    gap: 2rem;
  }
`;

const SubscriptionOffersBlock: FC<SubscriptionOffersBlockProps> = ({
  block,
  includeOfferIds,
  hasSubscriptionForThisPage,
}) => {
  const { offersToDisplay } = block;
  const { offers, offersStylesConfig } = useOffers();

  const offersIdsToDisplay = useMemo(
    () => offersToDisplay?.map((offer) => (isOffer(offer) ? offer.magineOfferId : '')),
    [offersToDisplay]
  );

  const filteredOffers = useMemo(
    () =>
      offers.filter((offer) =>
        offersIdsToDisplay?.length && offer.node.id ? offersIdsToDisplay.includes(offer.node.id) : true
      ),
    [offers, offersIdsToDisplay]
  );

  const getOfferStyleConfig = useCallback(
    (id: string) => offersStylesConfig?.find((stylesConfig) => stylesConfig.magineOfferId === id),
    [offersStylesConfig]
  );

  return (
    <OffersContainer>
      {!hasSubscriptionForThisPage &&
        filteredOffers?.map((offer) => (
          <OfferLarge
            data={offer.node}
            offerStylesConfig={getOfferStyleConfig(offer.node.id)}
            key={offer.node.id}
            includeOfferIds={includeOfferIds}
          />
        ))}
    </OffersContainer>
  );
};

export default SubscriptionOffersBlock;
