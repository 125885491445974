import styled from '@emotion/styled';
import { FunctionComponent, useState } from 'react';

import ArrowBlack from '../../assets/icons/arrow_black.svg';
import { arrowCursorDataURI } from '../../components/ThemedCarousel/utils';

type Directions = 'right' | 'left' | 'top' | 'bottom';

interface ArrowIconProps {
  color?: string;
  direction?: Directions;
  size?: number;
  hoverColor?: string;
  arrowSource?: string;
  stroke?: number;
}

interface StyledArrowProps {
  direction?: Directions;
  color?: string;
  size?: number;
  hoverColor?: string;
}

const getDegree = (direction?: Directions) => {
  switch (direction) {
    case 'bottom':
      return 90;
    case 'left':
      return 180;
    case 'top':
      return 270;
    case 'right':
      return 0;
    default:
      return 0;
  }
};

const StyledArrow = styled.img<StyledArrowProps>`
  transform: ${(props) => `rotateY(0deg) rotate(${getDegree(props.direction)}deg)`};
  width: ${(props) => (props.size ? `${props.size}px` : 'initial')};
`;

const ArrowIcon: FunctionComponent<ArrowIconProps> = ({ direction, color, size, hoverColor, arrowSource, stroke }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <StyledArrow
      hoverColor={hoverColor}
      src={
        isHovered
          ? arrowCursorDataURI(hoverColor, 'right', stroke || 3)
          : arrowCursorDataURI(color, 'right', stroke || 3)
      }
      alt=""
      direction={direction}
      color={color}
      size={size}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    />
  );
};
export { ArrowIcon };
